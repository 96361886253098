/***
 * Root div added just before </body>
 */

#tarteaucitronRoot {
  @extend .modal;
  overflow: auto;
  position: fixed;
  bottom: 0;
  z-index: auto;
  &.tarteaucitronBeforeVisible {
    display:block;
  }
  #tarteaucitronClosePanel {
    all:unset;
    font-size: 0;
    &:after {
      display: block;
      content: "x";
      right: 0;
      background: $primary;
      color: $white;
      padding: .5rem 1rem;
      position: absolute;
      text-align: right;
      height: 1rem;
      top: 0.2rem;
      font-weight: 900;
      font-size: 1.3rem;
      cursor: pointer;
      line-height: 1.2rem;
      z-index: 99999;
    }
  }
  #tarteaucitronBack {
    display: none!important;
  }
  #tarteaucitron {
    @extend .modal-dialog;
    display: none;
    pointer-events: all;
    z-index: 1051;
    max-width: 100vw;
    margin:0;
    width: 100%;
    position: relative;
    @include media-breakpoint-up(md) {
      max-width: 75vw;
      margin:0.5rem;
    }
    @include media-breakpoint-up(lg) {
      max-width: 60vw;
    }

    #tarteaucitronServices {
      background: $body-bg;
      padding: $spacer;
      position: relative;
      z-index: 9999;
      top:-1.3rem;
      box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.5);

      ul {
        list-style: none;
        padding:0;
      }
      .tarteaucitronDetails {
        display: none;
        border-bottom: 1px solid;
      }

      .tarteaucitronTitle {
        border-top: 1px solid;
        button {
          all: unset;
          @extend .nav-item;
          @extend a;
          cursor: pointer;
          color: $primary !important;

          &:hover {
            text-decoration: underline !important;
          }
        }
      }
    }

    #tarteaucitronMainLineOffset {
      @extend .modal-body;
      background: $body-bg;
      color: $white;
      position: relative;
      z-index: 9999;
    }

    .tarteaucitronBorder {
      @extend .modal-body;
      position: relative;
      z-index: 9999;
      color: $white;
    }
  }

  #tarteaucitronAlertBig {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: $body-bg;
    color: $white;
    text-align: center;
    padding: calc(2 * #{$spacer});
    box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.5);
    #tarteaucitronPersonalize, #tarteaucitronCloseAlert, #tarteaucitronPrivacyUrl {
      @extend .btn;
      @include media-breakpoint-down(md) {
        width: 100%;
        margin:0.5rem 0;
      }
    }

    #tarteaucitronPersonalize, #tarteaucitronPrivacyUrl, {
      // accept all
      @include button-variant($primary, $white);
    }

    #tarteaucitronCloseAlert {
      @include button-variant($secondary, $white);
      // personalize
    }
  }

  #tarteaucitronPrivacyUrlDialog {
    all: unset;
    @extend .nav-item;
    @extend a;
    cursor: pointer;
    color: $primary !important;
    &:hover {
      text-decoration: underline !important;
    }
  }
  .tarteaucitronAllow, .tarteaucitronDeny {
    @extend .btn;
    @include button-variant($success, $white);
    @include media-breakpoint-down(md) {
      width: 100%;
      margin:0.25rem 0;
    }
  }
  .tarteaucitronDeny {
    @include button-variant($danger, $white);
  }

  #tarteaucitronAlertSmall {
    display: none !important;
  }

  #tarteaucitronBack {
    display: none;
  }

  .tarteaucitronH1 {
    font-size: 2rem;
    @include media-breakpoint-up(md) {
      font-size: 3rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 4rem;
    }
    color: $primary;
  }

  .tarteaucitronH2 {
    font-size: 1rem;
    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 3rem;
    }
  }

}

/***
 * Control panel
 */

#tarteaucitronBack {
  @extend .btn;
  @include button-variant($primary, $primary);
}
