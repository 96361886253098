
@import "~bootstrap";
@import "~bootstrap/scss/variables";
$body-bg:rgb(128, 128, 128);
$primary: $danger;
$secondary: $dark;
@import "tarteaucitron";
body {
  min-height: 100vh;
  width: 100%;
  background: $body-bg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: $navbar-brand-height + $spacer * 2;
  .container.login {
    height: calc(100vh - #{$navbar-brand-height + $spacer * 2});
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
  nav {
    z-index: 999;
    top:0;
  }
  header {
    height: calc(100vh - #{$navbar-brand-height + $spacer * 2});
    position: relative;

    div {
      height: 100%;
      position: relative;
    }

    .image {
      background: url(../img/apo-courier.svg);
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: center;
      min-width: 100%;
      left: 0;
      right: 0;
      top: -10%;
      position: absolute;
      background-size: 90% 90%;
    }

    .badge {
      background: #ce3c35;
      width: 10rem;
      height: 10rem;
      font-size: 1.25rem;
      border-radius: 50%;
      padding-top: 2rem;
      position: absolute;
      margin: auto;
      transform: translateY(-130%) translateX(10%) rotate(-25deg);
      box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.5);
      text-shadow: 2px 2px #a1312c;
      top: 0;
      bottom: 0;
    }

    .width-to-height {
      margin-top: 100%;
    }

    .header-caption {
      top: auto;
      bottom: 0;
      width: 100%;
      height: auto;
    }
  }

  .server svg {
    max-height: 80%;
  }

  svg {
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    stroke: #000;
  }

  path {
    fill: transparent;
  }

  @keyframes stroke {
    0% {
      stroke: #ffffff;
      stroke-dashoffset: 2000;
    }
    100% {
      stroke: #ffffff;
      stroke-dashoffset: 0;
    }
  }

  @keyframes fill {
    0% {
      fill: transparent;
    }
    100% {
      fill: #ffffff;
    }
  }

  #list.fill svg {
    stroke-dashoffset: 0;
    stroke: transparent;
    animation: stroke 2s forwards .5s;
  }

  #list.fill svg path {
    animation: fill 2s forwards 1.5s;
  }

  .bullets {
    font-size: 1rem;
    line-height: 3rem;
  }

  .bullets ul {
    @extend .text-white;
    @extend .my-4;
    @extend .px-5;
    @extend .px-md-0;
    @extend .list-group;
  }

  .bullets li {
    margin: .75rem 0;
    display: block;
    position: relative;
  }

  .bullets li:before {
    background: url(../img/math-plus.svg);
    content: '';
    position: absolute;
    left: -1.5rem;
    height: 1rem;
    width: 1rem;
    display: block;
    top: 1rem;
  }

  .emailInput {
    border-style: none
  }

  #textBadge {
    height: 100%;
    width: 100%;
    text-align: center
  }

  .text-center.bannerHaustuerText {
    color: #5d5d5d;
    font-size: 2rem
  }

  .height {
    height: 50px
  }

  .dots {
    background-repeat: round no-repeat;
    background-position: bottom;
    background-size: 1rem;
  }

  .center-dot {
    background-position: center center;
  }

  .horizontal-dot {
    background-image: url(../img/dot.svg);
    background-repeat: round no-repeat;
  }

  .verti-dot {
    background-image: url(../img/dotVert.svg);
    background-repeat: no-repeat round;
  }

  .left-dot {
    background-position: left;
  }

  .right-dot {
    background-position: right;
  }

  .bg-dark {
    background-color: #5d5d5d !important;
  }

  footer .bg-dark {
    background: rgb(128, 128, 128) !important;
  }

  a {
    color: #d2d2d2;
  }

  a:hover {
    color: #a5a5a5;
  }

  .btn-accordion {
    font-size: 1.5rem;
  }

  @media (min-width: 576px) {
    header .badge {
      width: 12.5rem;
      height: 12.5rem;
      font-size: 1.5rem;
      padding-top: 2.75rem;
    }
  }

  @media all and (min-width: 768px) {
    header .badge {
      width: 12.5rem;
      height: 12.5rem;
      font-size: 1.5rem;
    }

    header .image {

      background-size: contain;
      top: 0%;
    }

    .bullets ul {
      margin-left: 3rem;
      font-size: 1.5rem;
    }

    .bullets li:before {
      height: 2rem;
      width: 2rem;
      left: -3rem;
      top: 0.5rem;
    }

    .height {
      height: 100px
    }

    .dots {
      background-size: 1.5rem;
    }
  }

  .mig {
    height: 50px;
    width: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../img/mig.png);
  }

  @media (min-width: 992px) {
    header .badge {
      width: 15rem;
      height: 15rem;
      font-size: 1.75rem;
    }
  }

  @media all and (-ms-high-contrast: none) {
    footer section .container .mig {
      max-height: 38px;
      max-width: 150px;
    }
  }
}
